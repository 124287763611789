import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import "./h.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OpenMenu: false,
    };
  }
  getClassNames = ({ isActive }) => (isActive ? "active" : "");

  render() {
    return (
      <>
        <header id="menu" className="site-header menu-desktop">
          <div className="header-bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-2 col-md-2 col-12">
                  <div className="logo">
                    <Link to="/">EncurtarLink</Link>
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-12">
                  <div className="main-menu">
                    <nav className="navigation">
                      <ul className="nav menu">
                        <li>
                          <NavLink
                            exact="true"
                            className={this.getClassNames}
                            to="/planos"
                          >
                            Planos e preços
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            exact="true"
                            className={this.getClassNames}
                            to="/criar-qr-code"
                          >
                            Criar QR Code
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            exact="true"
                            className={this.getClassNames}
                            to="https://linkes.bio/?referal=encurtarlink"
                            target="_blank"
                          >
                            Páginas bio
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            exact="true"
                            className={this.getClassNames}
                            to="/baixar-app"
                          >
                            Baixar App
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            exact="true"
                            className={this.getClassNames}
                            to="/extensao"
                          >
                            Extensão
                          </NavLink>
                        </li>
                      </ul>
                    </nav>
                  </div>

                  <div class="sys-web">
                    <NavLink exact="true" to="/cadastre-se" className="button">
                      Cadastre-se
                    </NavLink>
                    <NavLink exact="true" to="/entrar" className="button">
                      Entrar
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="site-header-mobile">
          <div className="inner-nav-mobile">
            <div className="logo-mbl">
              <Link to="/">EncurtarLink</Link>
            </div>
            <div className="menu-mobile">
              <button
                onClick={() => {
                  this.setState({ OpenMenu: !this.state.OpenMenu });
                }}
              >
                {this.state.OpenMenu == true ? (
                  <>
                    {" "}
                    <FontAwesomeIcon icon={faClose} />
                  </>
                ) : (
                  <>
                    {" "}
                    <FontAwesomeIcon icon={faBars} />
                  </>
                )}
              </button>
            </div>
          </div>
          {this.state.OpenMenu == true && (
            <div className="slide-menu">
              <div
                className="inner"
                onClick={() => {
                  this.setState({ OpenMenu: false });
                }}
              ></div>
              <div className="area-menu-slider">
                <nav className="navigation">
                  <ul className="nav menu">
                    <li>
                      <NavLink
                        exact="true"
                        className={this.getClassNames}
                        to="/planos"
                      >
                        Planos e preços
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact="true"
                        className={this.getClassNames}
                        to="/criar-qr-code"
                      >
                        Criar QR Code
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact="true"
                        className={this.getClassNames}
                        to="https://linkes.bio/?referal=encurtarlink"
                        target="_blank"
                      >
                        Páginas bio
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact="true"
                        className={this.getClassNames}
                        to="/baixar-app"
                      >
                        Baixar App
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Header;
