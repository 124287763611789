import React, { Component } from "react";
import { Link } from "react-router-dom";
import { StaticDialog, useDialog } from "react-st-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBars, faLink } from "@fortawesome/free-solid-svg-icons";
import img from "../../img/navegadores.svg";
import f from "../../img/firefox.svg";
import g from "../../img/google.svg";
class Extensao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }
  componentDidMount() {
    document.title = "Extensão";
  }
  render() {
    return (
      <>
        <section id="about-us" className="work section">
          <div className="area-404">
            <img src={img} style={{ width: 400 }} />
            <h4> Baixe Nossa Extensão Encurtadora de Links</h4>
            <span>
              Torne o compartilhamento de links mais simples e rápido com nossa
              extensão encurtadora de URLs para navegadores.{" "}
            </span>
            <button
              className="btn-1"
              onClick={() => {
                this.setState({ modal: true });
              }}
            >
              <FontAwesomeIcon icon={faLink} />
              Baixar
            </button>
          </div>
        </section>

        <StaticDialog
          isOpen={this.state.modal}
          title="Escolha a plataforma"
          onAfterClose={(result) => {
            this.setState({ modal: false });
          }}
        >
          <div
            className="btns"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: 10,
            }}
          >
            <Link
              target="_blank"
              to="https://chromewebstore.google.com/detail/encurtarlink/kmifcofkojkmmhkhpokkaecaoohepnld"
            >
              <img src={g} style={{ width: 40, height: 40 }} />
              Google chrome
            </Link>

            <Link
              target="_blank"
              to="https://addons.mozilla.org/pt-BR/firefox/addon/encurtarlink/"
              style={{ marginLeft: 10 }}
            >
              <img src={f} style={{ width: 40, height: 40 }} />
              Firefox
            </Link>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default Extensao;
