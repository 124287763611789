import React, { Component } from "react";

class Privacy extends Component {
  render() {
    return (
      <div className="container" style={{ marginTop: 150 }}>
        <h1>Política de Privacidade</h1>
        <p>
          Bem-vindo ao <strong>EncurtarLink</strong>! A sua privacidade é
          importante para nós. Esta Política de Privacidade explica como
          coletamos, usamos e protegemos suas informações ao utilizar nosso site{" "}
          <a href="https://www.encurtarlink.com.br/">
            https://www.encurtarlink.com.br/
          </a>
          .
        </p>

        <h2>1. Coleta de Informações</h2>
        <p>
          Podemos coletar os seguintes tipos de informações:
          <ul>
            <li>
              <strong>Informações fornecidas pelo usuário:</strong> Nome, e-mail
              e outras informações opcionais ao entrar em contato conosco.
            </li>
            <li>
              <strong>Informações automáticas:</strong> Endereço IP, tipo de
              navegador, páginas visitadas e tempo de permanência no site,
              coletadas por meio de cookies e ferramentas analíticas.
            </li>
          </ul>
        </p>

        <h2>2. Uso das Informações</h2>
        <p>
          As informações coletadas são utilizadas para:
          <ul>
            <li>Melhorar a experiência do usuário em nosso site;</li>
            <li>Analisar métricas de uso e desempenho do site;</li>
            <li>Garantir a segurança e evitar fraudes;</li>
            <li>Entrar em contato com o usuário quando solicitado.</li>
          </ul>
        </p>

        <h2>3. Compartilhamento de Dados</h2>
        <p>
          Não vendemos ou compartilhamos suas informações pessoais com
          terceiros, exceto nos seguintes casos:
          <ul>
            <li>Para cumprir obrigações legais;</li>
            <li>
              Para proteção dos direitos do <strong>EncurtarLink</strong>;
            </li>
            <li>
              Com serviços de terceiros que ajudam na operação do site, sempre
              em conformidade com esta política.
            </li>
          </ul>
        </p>

        <h2>4. Cookies e Tecnologias Semelhantes</h2>
        <p>
          Utilizamos cookies para melhorar a navegação e personalizar conteúdo.
          Você pode configurar seu navegador para recusar cookies, mas isso pode
          afetar a experiência no site.
        </p>

        <h2>5. Segurança das Informações</h2>
        <p>
          Adotamos medidas de segurança para proteger seus dados, mas nenhuma
          transmissão de informações pela internet é totalmente segura.
          Portanto, não podemos garantir segurança absoluta.
        </p>

        <h2>6. Direitos do Usuário</h2>
        <p>
          Você pode solicitar a exclusão ou alteração dos seus dados pessoais
          entrando em contato conosco pelo e-mail{" "}
          <strong>contato@encurtarlink.com.br</strong>.
        </p>

        <h2>7. Alterações nesta Política</h2>
        <p>
          Podemos atualizar esta política periodicamente. Recomendamos que você
          reveja esta página regularmente para estar ciente de eventuais
          mudanças.
        </p>

        <h2>8. Contato</h2>
        <p>
          Se tiver dúvidas sobre esta Política de Privacidade, entre em contato
          pelo e-mail <strong>contato@encurtarlink.com.br</strong>.
        </p>

        <p>
          <strong>Data da última atualização: 25/02/2025</strong>
        </p>
      </div>
    );
  }
}

export default Privacy;
