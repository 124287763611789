import React, { Component } from "react";
import { Link } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import "./l.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDollar,
  faBoxOpen,
  faChartBar,
  faStore,
  faCheckCircle,
  faEye,
  faEyeSlash,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import "./e.css";
import g from "../../img/g.svg";
import img from "../../img/undraw_authentication_re_svpt.svg";
import { toast } from "react-toastify";
import { apiUrl } from "../../comp/ApiUrl";

class Entrar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      senha: "",
      mcnt: false,
      vsenha: false,
      spass: false,
    };
  }
  componentDidMount() {
    document.title = "Entrar";
  }

  setCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  render() {
    return (
      <>
        <div className="logo-l">
          <Link to="/">
            {" "}
            <FontAwesomeIcon icon={faArrowLeft} fontSize={25} /> Encurtar Link
          </Link>
        </div>
        <div class="container-fluid full-height a-lg">
          <div class="row">
            <div
              style={{ background: "#512b78" }}
              class="col-12 col-md-12   d-flex justify-content-center align-items-center"
            >
              <div class="container-2">
                <div class="left">
                  <h2>Olá!</h2>
                  <div className="d-flex justify-content-center">
                    <GoogleOAuthProvider clientId="586866188069-48efhu7s9kh1ule7to1giva025amf4eb.apps.googleusercontent.com">
                      <GoogleLogin
                        onSuccess={(data) => {
                          fetch(`${apiUrl}/google-login/`, {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ token: data.credential }),
                          })
                            .then((resp) => {
                              if (resp.status == 200) {
                                resp.json().then((data) => {
                                  window.localStorage.setItem(
                                    "token",
                                    data.token
                                  );
                                  window.location = window.location.origin;
                                });
                              }
                            })
                            .catch((err) => {
                              toast.error(
                                "Falha na comunicação com o servidor!"
                              );
                            });
                        }}
                        onError={(err) => {
                          toast.error("Falha ao fazer login com Google");
                        }}
                      />
                    </GoogleOAuthProvider>
                  </div>
                  <p className="text-center mt-3">Ou</p>
                  <hr />
                  <div>
                    <p style={{ fontSize: 14, textAlign: "center" }}>
                      Informe o seu email de cadastro e sua senha para acessar o
                      painel.
                    </p>
                  </div>

                  <div class="container mt-1 ipts-lg">
                    <div class="mb-3">
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="Digite seu email:"
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                        required
                      />
                    </div>
                    <div class="mb-3 area-spass">
                      <input
                        type={this.state.spass ? "text" : "password"}
                        class="form-control"
                        id="senha"
                        placeholder="Digite sua senha:"
                        onChange={(e) => {
                          this.setState({ senha: e.target.value });
                        }}
                        required
                      />
                      <button
                        className="spass"
                        onClick={() => {
                          this.setState({ spass: !this.state.spass });
                        }}
                      >
                        {this.state.spass ? (
                          <>
                            <FontAwesomeIcon icon={faEyeSlash} />
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faEye} />
                          </>
                        )}
                      </button>
                    </div>
                    <div
                      className="rp-sen"
                      style={{ marginTop: -8, marginBottom: 10 }}
                    >
                      <Link>Esqueceu a senha?</Link>
                    </div>
                    <button
                      type="submit"
                      style={{ width: "100%" }}
                      onClick={(e) => {
                        var { email, senha } = this.state;

                        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                        if (email.trim().length == 0) {
                          toast.error("O campo de email é obrigatório!");
                          return;
                        }

                        if (regex.test(email) == false) {
                          toast.error("Formato de enail inválido!");
                          return false;
                        }

                        if (senha.trim().length == 0) {
                          toast.error("O campo de senha é obrigatório!");
                          return;
                        }

                        e.target.classList.add("load");

                        fetch(`${apiUrl}/oauth/login`, {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({ email, senha }),
                        })
                          .then((resp) => {
                            e.target.classList.remove("load");

                            if (resp.status == 404) {
                              toast.error(
                                "Combinação errada de e-mail e senha."
                              );
                              return;
                            }
                            if (resp.status == 403) {
                              toast.info(
                                "Você excedeu o número permitido de tentativas de login. Por favor, aguarde alguns minutos antes de tentar novamente."
                              );
                              return false;
                            }
                            if (resp.status == 200) {
                              resp.json().then((data) => {
                                window.localStorage.setItem(
                                  "token",
                                  data.token
                                );
                                window.location = window.location.origin;
                              });
                            }
                          })
                          .catch((err) => {
                            e.target.classList.remove("load");
                            toast.error("Falha na comunicação com o servidor!");
                          });
                      }}
                      class="btn btn-primary"
                    >
                      ENTRAR NO PAINEL
                    </button>
                  </div>
                </div>
                <div class="right apst">
                  <h2>Não tem uma conta?</h2>
                  <p>
                    Crie links personalizados, link do WhatsApp, QR Code, Link
                    bio e muito mais.{" "}
                  </p>
                  <button
                    className="btn btn-light"
                    onClick={() => {
                      this.props.navigate("/cadastro");
                    }}
                  >
                    Criar conta
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Entrar;
