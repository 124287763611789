import React, { Component } from "react";
import { Link } from "react-router-dom";
import { StaticDialog, useDialog } from "react-st-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBars, faLink } from "@fortawesome/free-solid-svg-icons";
import img from "../../img/baixar.svg";
import gplay from "../../img/gplay.svg";
import aplay from "../../img/aplay.svg";
class BaixarApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }
  componentDidMount() {
    document.title = "Baixar App";
  }
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div className="area-404">
          <img src={img} style={{ width: 400 }} />
          <h4>Baixe Nosso App Encurtador de Links</h4>
          <span>
            Simplifique o compartilhamento de URLs com nosso aplicativo
            encurtador de links.
          </span>
          <button
            className="btn-1"
            onClick={() => {
              this.setState({ modal: true });
            }}
          >
            <FontAwesomeIcon icon={faLink} />
            Baixar
          </button>
        </div>

        <StaticDialog
          isOpen={this.state.modal}
          title="Escolha a plataforma"
          onAfterClose={(result) => {
            this.setState({ modal: false });
          }}
        >
          <div
            className="btns"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: 10,
            }}
          >
            <Link to="/" disabled>
              <img src={aplay} style={{ width: 40, height: 40 }} />
              Baixar no IOS
            </Link>

            <Link to="/" style={{ marginLeft: 10 }} disabled="true">
              <img src={gplay} style={{ width: 40, height: 40 }} />
              Baixar no Android
            </Link>
          </div>
        </StaticDialog>
      </div>
    );
  }
}

export default BaixarApp;
